import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Wave } from '@fingo/lib/assets';
import { FullHeightBox } from '@fingo/lib/components/boxes';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { WelcomeHeader } from '@fingo/lib/components/headers';
import { isLoggedInQuery } from '@fingo/lib/apollo/cache/localQuery';
import { Paper, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLogin from '../hooks/useLogin';

const MARGIN = 3;
const TEXT_WIDTH = 250;
const Login = () => {
  const [formInput, setFormInput] = useState({ email: '', password: '' });
  const {
    data: { isLoggedIn: isLogged },
  } = useQuery(isLoggedInQuery);
  const [loginGql, loadingLogin, errorForm] = useLogin(formInput.email, formInput.password);
  const formChange = (variable, event) => {
    setFormInput((prev) => ({ ...prev, [variable]: event.target.value }));
  };

  if (isLogged) return <Redirect to="/app/home" />;

  return (
    <FullHeightBox
      sx={{
        backgroundImage: `url(${Wave})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        backgroundPosition: 'bottom',
      }}
    >
      <WelcomeHeader topMessage="Iniciar" headerMessage="Lucila" bottomMessage="sesión" />
      <Paper
        sx={{
          width: 450,
          pb: 8,
          mr: 'auto',
          ml: 'auto',
        }}
        elevation={0}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            loginGql();
          }}
          id="loginForm"
        >
          <Grid container alignItems="center" direction="column">
            <ValidationTextFieldInput
              name="email"
              type="text"
              label="Email"
              variant="standard"
              sx={{ width: TEXT_WIDTH, mt: 8 }}
              value={formInput.email}
              onChange={(event) => formChange('email', event)}
            />
            <PasswordInput
              name="password"
              label="Contraseña"
              variant="standard"
              sx={{ width: TEXT_WIDTH, mt: MARGIN }}
              value={formInput.password}
              onChange={(event) => formChange('password', event)}
              error={errorForm?.password}
              helperText={errorForm.password}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              sx={{
                mt: MARGIN,
                width: 200,
              }}
              type="submit"
              loading={loadingLogin}
              disabled={!formInput.email.length || !formInput.password.length}
            >
              Ingresar
            </LoadingButton>
          </Grid>
        </form>
      </Paper>
    </FullHeightBox>
  );
};

export default Login;
