import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'moment/locale/es';
import React, { useMemo } from 'react';
import { render } from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import SnackBarProvider from '@fingo/lib/contexts/snack-bar';
import TrackerProvider from '@fingo/lib/components/tracker/TrackerProvider';
import theme from '@fingo/lib/theme';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TranslationProvider from '@fingo/lib/components/translation/TranslationProvider';
import client from '@fingo/lib/apollo';
import { dateCaster } from '@fingo/lib/helpers';
import { Login, App } from './views';
import PrivateRoute from './components/router/PrivateRoute';
import { Layout } from './components/layout';

const Lucila = () => {
  dateCaster();
  const component = useMemo(() => (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ApolloProvider client={client}>
            <StyledEngineProvider injectFirst>
              <SnackBarProvider>
                <Router>
                  <TranslationProvider>
                    <TrackerProvider frontendName="lucila">
                      <Layout>
                        <Switch>
                          <PrivateRoute path="/app" component={App} />
                          <Route exact path="/login" component={Login} />
                          <Route exact path="/" component={Login} />
                        </Switch>
                      </Layout>
                    </TrackerProvider>
                  </TranslationProvider>
                </Router>
              </SnackBarProvider>
            </StyledEngineProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </CssBaseline>
    </ThemeProvider>
  ), []);
  return component;
};

render(<Lucila />, document.getElementById('root'));
